import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import HeaderBottom from "../components/HeaderBottom";

const AboutPageTemplate = ({ data, pageContext, location }) => {
  const page = data.home.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "scroll-is--active appear-animate",
        }}
      />
    <div class="page-loader dark">
            <div class="loader">Loading...</div>
        </div>



        <a href="#main" class="btn skip-to-content">Skip to Content</a>



        <div class="page bg-dark light-content" id="top">


            <nav class="main-nav dark transparent stick-fixed wow-menubar">
                <div class="full-wrapper relative clearfix">


                    <div class="nav-logo-wrap local-scroll">
                        <a href="/" class="logo">
                            <img src="/asset/images/huxlogo_white.png" alt="Company logo" />
                        </a>
                    </div>


                    <div class="mobile-nav" role="button" tabindex="0">
                        <i class="fa fa-bars"></i>
                        <span class="sr-only">Menu</span>
                    </div>


                    <a href="/asset/shop-cart-dark.html" class="mobile-cart"><i class="main-nav-icon-cart"></i> (0)</a>


                    <div class="inner-nav desktop-nav">
                        <ul class="clearlist">

                        <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/"><i class="fas fa-home"></i> Home</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/about"><i class="fas fa-dice-d20"></i> About</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href={page.tokopediaUrl}><i class="main-nav-icon-cart"></i> Shop</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/#tutorial"><i class="fas fa-spa"></i> Tutorial</a>
                            </li>
                            <li class="d-sm-none d-md-none d-lg-block">
                                <a href="/#blog"><i class="fas fa-book-open"></i> Blog</a>
                            </li>

                        </ul>
                    </div>


                </div>
            </nav>


            <main id="main">


                <section class="small-section bg-dark-alfa-50 bg-scroll light-content" data-background="/asset/images/full-width-images/section-bg-19.jpg" id="home">
                    <div class="container relative pt-70">

                        <div class="row">

                            <div class="col-md-8">
                                <div class="wow fadeInUpShort" data-wow-delay=".1s">
                                    <h1 class="hs-line-7 mb-20 mb-xs-10">About Us</h1>
                                </div>
                                <div class="wow fadeInUpShort" data-wow-delay=".2s">
                                    <p class="hs-line-6 opacity-075 mb-20 mb-xs-0">
                                        Internationally Made, Locally Loved
                                    </p>
                                </div>
                            </div>

                            <div class="col-md-4 mt-30 wow fadeInUpShort" data-wow-delay=".1s">
                                <div class="mod-breadcrumbs text-end">
                                    <a href="#">Home</a>&nbsp;<span class="mod-breadcrumbs-slash">•</span>&nbsp;<a href="#">Pages</a>&nbsp;<span class="mod-breadcrumbs-slash">•</span>&nbsp;<span>About Us</span>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>




                <section class="page-section bg-dark light-content" id="about">
                    <div class="container relative">

                        <div class="section-text mb-60 mb-sm-40">
                            <div class="row">

                                <div class="col-md-6 mb-md-50 mb-xs-30 wow fadeInUpShort" data-wow-offset="0">
                                    <p>
                                      The attitude toward traditional masculinity has undergone a major transformation over the last few years. At HUX, we have been paying keen attention to  your needs for a few years and offer products that address them. We offer a curated selection of grooming and self-care must-haves to ensure you present your best self. Along the process we show you how to take care of yourself, things you can do to better your life. The products available with use advanced european technology to create uncomplicated self-care for the modern, design-conscious individuals. Keeping your skin, hair, body and mind healthy and happy shouldn't be complicated.
                                    </p>
                                </div>

                                <div class="col-md-6 mb-md-50 mb-xs-30 wow fadeInUpShort" data-wow-offset="0">


                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">
                                            <div>Technology & Engineering, %</div> <span>95</span>
                                        </div>
                                    </div>



                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                            <div>Design, %</div> <span>80</span>
                                        </div>
                                    </div>



                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                                            <div>Research, %</div> <span>85</span>
                                        </div>
                                    </div>



                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100">
                                            <div>Customer Service, %</div> <span>99</span>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>


                        <div class="count-wrapper">
                            <div class="row">


                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1s">
                                            <div class="count-number">
                                                920
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-briefcase mr-2"></i>
                                                <span class="count-title">"Projects" Done</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1.1s">
                                            <div class="count-number">
                                                1050
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-heart"></i>
                                                <span class="count-title">Happy Customers</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1.2s">
                                            <div class="count-number">
                                                1000
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-coffee"></i>
                                                <span class="count-title">Confidence Boosts</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1.3s">
                                            <div class="count-number">
                                                98
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-lightbulb"></i>
                                                <span class="count-title">Great Ideas</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                </section>

                <section class="page-section bg-dark-lighter light-content">
                    <div class="container relative">

                        <div class="text-center mb-80 mb-sm-50">
                            <h2 class="section-title">Our Story</h2>
                            <p class="section-title-descr">
                                Learn more about our company.
                            </p>
                        </div>


                        <div class="row">

                            <div class="col-sm-8 offset-sm-2 wow fadeInUpShort">


                                <div class="align-center mb-40 mb-xxs-30">
                                    <ul class="nav nav-tabs tpl-minimal-tabs animate" role="tablist">

                                        <li class="nav-item">
                                            <a href="#mission" aria-controls="mission" class="nav-link active" data-bs-toggle="tab" role="tab" aria-selected="true">Mission</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="#vision" aria-controls="vision" class="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">Vision</a>
                                        </li>

                                        <li class="nav-item">
                                            <a href="#ideas" aria-controls="ideas" class="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">Ideas</a>
                                        </li>

                                    </ul>
                                </div>



                                <div class="tab-content tpl-minimal-tabs-cont section-text align-center">

                                    <div class="tab-pane fade show active" id="mission" role="tabpanel">
                                    Our mission is to make self-care easy and hassle-free for modern men who want to look and feel their best every day. To achieve this, we only use the best material and the best world-class engineering technology has to offer.
                                    </div>

                                    <div class="tab-pane fade" id="vision" role="tabpanel">
                                        We believe that the industry is rapidly growing especially in growing markets like south east asia, where men are becoming increasingly more aware of their personal self-care needs. This is where we come in, for you.
    								</div>

                                    <div class="tab-pane fade" id="ideas" role="tabpanel">
                                        We are a growing and thriving business in the men's essentials space and are continuously coming up with new designs, products so that you, our beloved consumer can upgrade yourself hassle-free knowing that we are working our hardest for your needs.
    								</div>

                                </div>


                            </div>

                        </div>


                    </div>
                </section>


                <section class="page-section bg-dark bg-dark-alfa-70 bg-scroll light-content" data-background="/asset/images/hux_bg0.jpeg">
                    <div class="container relative">

                    <div class="row">
                        <div class=" col-lg-8 offset-lg-2 wow fadeInUpShort">

                            <div class="text-center mb-50 mb-sm-20">
                                <h2 class="section-title">Apa Yang Dikatakan Customer Kami?</h2>
                            </div>

                            <div class="text-slider">


                            {page.testimonials.map(testimony => {
                                  return (
                                    <div>
                                        <blockquote class="testimonial">
                                            <p>
                                            {testimony.text}
                                            </p>
                                            <footer class="testimonial-author mt-50 mt-sm-20">
                                                — {testimony.name}
                                            </footer>
                                        </blockquote>
                                    </div>
                                  )
                                })}


                            </div>

                        </div>
                    </div>

                    </div>
                </section>




                <section class="small-section pt-20 pb-20 bg-dark-lighter light-content">
                    <div class="container relative">

                        <div class="row wow fadeInUpShort">
                            <div class="col-md-12">

                                <div class="small-item-carousel black owl-carousel mb-0">
                                    {page.aboutUsImages.map(e => {
                                      return (
                                        <div class="logo-item">
                                            <img src="/asset/images/clients-logos/dark/client-1.png" width="150" height="90" alt="Company Name" />
                                        </div>
                                      )
                                    })}


                                </div>

                             </div>
                         </div>

                     </div>
                </section>




                <hr class="mt-0 mb-0 white" />









                <section class="page-section bg-dark light-content" id="contact">
                    <div class="container relative">

                        <div class="text-center mb-80 mb-sm-50">
                            <h2 class="section-title">Contact</h2>
                            <p class="section-title-descr">
                                We’re available for the new projects
                            </p>
                        </div>

                        <div class="row mb-60 mb-xs-40">

                            <div class="col-md-10 offset-md-1">
                                <div class="row">


                                    <div class="col-sm-6 col-lg-4 pb-20">
                                        <div class="contact-item wow fadeScaleIn" data-wow-delay="0" data-wow-duration="1s">
                                            <div class="ci-icon">
                                                <i class="fa fa-phone-alt"></i>
                                            </div>
                                            <div class="ci-title">
                                                Call Us
                                            </div>
                                            <div class="ci-text">
                                                {page.waNum}
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-sm-6 col-lg-4 pb-20">
                                        <div class="contact-item wow fadeScaleIn" data-wow-delay=".1s" data-wow-duration="1s">
                                            <div class="ci-icon">
                                                <i class="fa fa-map-marker-alt"></i>
                                            </div>
                                            <div class="ci-title">
                                                Address
                                            </div>
                                            <div class="ci-text">
                                                Pulogadung, Jakarta Timur
                                            </div>
                                            {/* <div class="ci-link">
                                                <a href="/asset/https://goo.gl/maps/7Vw1PBhxx5BaXStr8" target="_blank">See on the Map</a>
                                            </div> */}
                                        </div>
                                    </div>



                                    <div class="col-sm-6 col-lg-4 pb-20">
                                        <div class="contact-item wow fadeScaleIn" data-wow-delay=".2s" data-wow-duration="1s">
                                            <div class="ci-icon">
                                                <i class="fa fa-envelope"></i>
                                            </div>
                                            <div class="ci-title">
                                                Email
                                            </div>
                                            <div class="ci-text">
                                                {page.email}
                                            </div>
                                            <div class="ci-link">
                                                <a href={`mailto:${page.email}`}>Say Hello</a>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>
                </section>



            </main>


            <footer class="page-section bg-dark-lighter light-content footer pb-100 pb-sm-50">
                <div class="container">


                    <div class="footer-social-links mb-90 mb-xs-40">
                        <a href={page.instaStoreUrl} title="Instagram" target="_blank"><i class="fab fa-instagram"></i> <span class="sr-only">Instagram Account</span></a>
                    </div>



                    <div class="footer-text">


                        <div class="footer-copy">
                            <a href="/asset/index.html">{`© ${page.companyName} ${new Date().getFullYear()}`}</a>
                        </div>


                        <div class="footer-made">
                            Made with love for great people.
                        </div>

                    </div>


                 </div>


                 <div class="local-scroll">
                     <a href="#top" class="link-to-top"><i class="link-to-top-icon"></i></a>
                 </div>


            </footer>


        </div>
    </Layout>
  );
};

export default AboutPageTemplate;

export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    home:markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        templateKey
        logo
        companyName
        waNum
        waMessage
        email
        instaStoreUrl
        tokopediaUrl
        companyAddress
        aboutUsImages {
          image
        }
        messageParagraph {
          messageBody
          messageHeader
        }
        testimonials {
          image
          text
          name
        }
        galleryvideos {
          title
          url
          image
        }
        galleryimages {
          title
          image
        }
        products {
          image
          productUrl
          name
          description
        }
        productGallery {
          label
          desc
          image
        }
        featuresGallery {
          label
          desc
          image
        }
        peopleGallery {
          label
          desc
          image
        }
        tutorial {
          title
          desc
          thumbnail
          video
        }
        tutorialMainImage
        id
        videoUrl
        videoThumbnail
      }
    }
  }
`;
